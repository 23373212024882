@keyframes shake {
    0% { transform: translateX(0); }
    25% { transform: translateX(-5px); }
    50% { transform: translateX(5px); }
    75% { transform: translateX(-5px); }
    100% { transform: translateX(0); }
  }
  
  .fade-out-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 1s ease-out;
    pointer-events: none;
  }
  
  .fade-out-overlay.active {
    background-color: rgba(0, 0, 0, 1);
  }
  
  .event-list-container {
    transition: opacity 1s ease-out;
  }
  
  .event-list-container.fade-out {
    opacity: 0;
    pointer-events: none;
  }
  
  .footer-container {
    transition: opacity 1s ease-out;
  }
  
  .footer-container.fade-out {
    opacity: 0;
    pointer-events: none;
  }