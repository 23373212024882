.funhouse-logo {
  max-width: 20vw;
  width: auto;
  height: auto;
  transition: all 0.5s ease;
}

.out-of-sight {
  /* Add styles to move the logo out of sight */
  transition: transform 0.3s ease; /* Add transition effect for smooth movement */
}
